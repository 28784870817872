import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

// nodejs library that concatenates classes
import classnames from 'classnames';

// reactstrap components
import {
    Button,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Row,
    Col
} from 'reactstrap';
import AvaiSources from './AvaiSources.js';

import client from '../../feathers.js';

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Email is invalid')
        .required('Email is required'),
    password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required')
});

const UserModal = (props) => {
    const [ emailFocused, setEmailFocused ] = useState(false);
    const [ passwordFocused, setPasswordFocused ] = useState(false);
    const [ confirmPasswordFocused, setConfirmPasswordFocused ] = useState(false);

    const onSubmit = async(value) => {
        value.username = value.email;
    
        client.authenticate()
            .then(()=>{
                return client.service('users').create(value);
            })
            .then((data)=>{
                const licenseData = {
                    startDate: value.startDate,
                    endDate: value.endDate,
                    userId : data._id,
                    numOfAccounts: value.numOfAccounts,
                    description: value.description,
                    status: value.status,
                };
                return client.service('licenses').create(licenseData);
            })
            .then((data)=>{
                const companyData = {
                    userId : data.userId,
                    postalCode: value.postalCode,
                    state: value.state,
                    country: value.country,
                    address: value.address,
                    companyName: value.companyName,
                    phoneNumber: value.phoneNumber,
                    email: value.email,
                    personName: [value.firstName, value.lastName].join(' '),
                    rateMYRtoSGD: 3,
                };
                return client.service('companies').create(companyData);
            })
            .then((res) => {
                props.toggleModal();
                props.notificationOpen(true, 'success', 'User ' + value.email + ' Added Successfully!');
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    props.notificationOpen(true, 'danger', err.message);
                }
            });
    };

    return (  
        <>
            <Modal
                className='modal-dialog-centered'
                isOpen={props.modalVisible}
                toggle={props.toggleModal}
            >
                <div className='modal-header'>
                    <h5 className='modal-title' id='exampleModalLabel'>
                        Add License
                    </h5>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={props.toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        confirmPassword: '',
                        firstName: '',
                        lastName: '',
                        phoneNumber: '',
                        address: '',
                        state: '',
                        country: '',
                        postalCode: '',
                        isInternal: false,
                        numOfAccounts: 0,
                        startDate: new Date(),
                        endDate: new Date(),
                        description: '',
                        status: 1,
                        role: 'superadmin',
                        currency: 'myr',
                        avaiSources: {
                            csv: true,
                            api: false,
                            apiSg: false,
                            apiEast: false,
                            poster: true,
                            looseSheet: true,
                            booklet: true,
                        }
                    }}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                >
                    {propsChild => {
                        const {
                            values,
                            touched,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        } = propsChild;
                        return (
                            <Form role='form' onSubmit={handleSubmit}>
                                <div className='modal-body pt-0'>
                                    <Row>
                                        <Col lg='12'>
                                            <label
                                                className='form-control-label'
                                                htmlFor='email'
                                            >
                                                Email Address
                                            </label>
                                            <FormGroup
                                                className={classnames('mb-3', {
                                                    focused: emailFocused
                                                }, {
                                                    'has-danger': errors.email && touched.email
                                                })}
                                            >
                                                <InputGroup className='input-group-alternative'>
                                                    <InputGroupAddon addonType='prepend'>
                                                        <InputGroupText>
                                                            <i className='ni ni-email-83' />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        placeholder='Email'
                                                        type='email'
                                                        id='email'
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onFocus={e =>
                                                            setEmailFocused(true)
                                                        }
                                                        onBlur={e => {
                                                            setEmailFocused(false);
                                                            handleBlur(e);
                                                        }}
                                                    />
                                                </InputGroup>
                                                {errors.email && touched.email && (
                                                    <div className='input-feedback'>{errors.email}</div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg='6'>
                                            <label
                                                className='form-control-label'
                                                htmlFor='Passowrd'
                                            >
                                                Password
                                            </label>
                                            <FormGroup
                                                className={classnames({
                                                    focused: passwordFocused
                                                }, {
                                                    'has-danger': errors.password && touched.password
                                                })}
                                            >
                                                <InputGroup className='input-group-alternative'>
                                                    <InputGroupAddon addonType='prepend'>
                                                        <InputGroupText>
                                                            <i className='ni ni-lock-circle-open' />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        placeholder='Password'
                                                        type='password'
                                                        autoComplete='off'
                                                        id='password'
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        onFocus={e =>
                                                            setPasswordFocused(true)
                                                        }
                                                        onBlur={e => {
                                                            setPasswordFocused(false);
                                                            handleBlur(e);
                                                        }}
                                                    />
                                                </InputGroup>
                                                {errors.password && touched.password && (
                                                    <div className='input-feedback'>{errors.password}</div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col lg='6'>
                                            <label
                                                className='form-control-label'
                                                htmlFor='confirmPassword'
                                            >
                                                Confirm Password
                                            </label>
                                            <FormGroup
                                                className={classnames({
                                                    focused: confirmPasswordFocused
                                                }, {
                                                    'has-danger': errors.confirmPassword && touched.confirmPassword
                                                })}
                                            >
                                                <InputGroup className='input-group-alternative'>
                                                    <InputGroupAddon addonType='prepend'>
                                                        <InputGroupText>
                                                            <i className='ni ni-lock-circle-open' />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        placeholder='Confirm Password'
                                                        type='password'
                                                        autoComplete='off'
                                                        id='confirmPassword'
                                                        value={values.confirmPassword}
                                                        onChange={handleChange}
                                                        onFocus={e =>
                                                            setConfirmPasswordFocused(true)
                                                        }
                                                        onBlur={e => {
                                                            setConfirmPasswordFocused(false);
                                                            handleBlur(e);
                                                        }}
                                                    />
                                                </InputGroup>
                                                {errors.confirmPassword && touched.confirmPassword && (
                                                    <div className='input-feedback'>{errors.confirmPassword}</div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg='6'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='firstName'
                                                >
                                                    First name
                                                </label>
                                                <Input
                                                    className='form-control-alternative'
                                                    id='firstName'
                                                    value={values.firstName}
                                                    onChange={handleChange}
                                                    placeholder='First name'
                                                    type='text'
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg='6'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='lastName'
                                                >
                                                    Last name
                                                </label>
                                                <Input
                                                    className='form-control-alternative'
                                                    id='lastName'
                                                    value={values.lastName}
                                                    onChange={handleChange}
                                                    placeholder='Last name'
                                                    type='text'
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col lg='6'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='currency'
                                                >
                                                    Currency
                                                </label>
                                                <Input 
                                                    className='form-control-alternative text-uppercase'
                                                    placeholder='Currency'
                                                    type='select' 
                                                    id='currency' 
                                                    value={values.currency}
                                                    onChange={handleChange}
                                                >
                                                    <option>myr</option>
                                                    <option>sgd</option>
                                                    <option>idr</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col lg='6'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='phoneNumber'
                                                >
                                                    Contact Number
                                                </label>
                                                <Input
                                                    className='form-control-alternative'
                                                    id='phoneNumber'
                                                    value={values.phoneNumber}
                                                    onChange={handleChange}
                                                    placeholder='Contact'
                                                    type='text'
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <hr className='my-4' />
                                    <Row>
                                        <Col lg='6'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='currency'
                                                >
                                                    Number of accounts
                                                </label>
                                                <Input
                                                    className='form-control-alternative'
                                                    type='number'
                                                    id='numOfAccounts'
                                                    value={Number(values.numOfAccounts).toString()}
                                                    min={1}
                                                    max={999999}
                                                    onChange={handleChange}
                                                    placeholder='Number Of Margins'
                                                    onWheel={ event => event.currentTarget.blur() }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg='6'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='currency'
                                                >
                                                    Start date
                                                </label>
                                                <Input
                                                    className='form-control-alternative'
                                                    type='date'
                                                    id='startDate'
                                                    value={values.startDate}
                                                    onChange={handleChange}
                                                    placeholder='Start Date'
                                                    onWheel={ event => event.currentTarget.blur() }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg='6'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='currency'
                                                >
                                                    End date
                                                </label>
                                                <Input
                                                    className='form-control-alternative'
                                                    type='date'
                                                    id='endDate'
                                                    value={values.endDate}
                                                    onChange={handleChange}
                                                    placeholder='End date'
                                                    onWheel={ event => event.currentTarget.blur() }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <hr className='my-4' />
                                    <Row>
                                        <Col lg='12'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='companyName'
                                                >
                                                    Company Name
                                                </label>
                                                <Input
                                                    className='form-control-alternative'
                                                    id='companyName'
                                                    value={values.companyName}
                                                    onChange={handleChange}
                                                    placeholder='Company Name'
                                                    type='text'
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg='12'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='couaddressntry'
                                                >
                                                    Address
                                                </label>
                                                <Input
                                                    className='form-control-alternative'
                                                    id='address'
                                                    value={values.address}
                                                    onChange={handleChange}
                                                    placeholder='Address'
                                                    type='textarea'
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg='6'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='country'
                                                >
                                                    Country
                                                </label>
                                                <Input
                                                    className='form-control-alternative'
                                                    id='country'
                                                    value={values.country}
                                                    onChange={handleChange}
                                                    placeholder='Country'
                                                    type='text'
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg='6'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='state'
                                                >
                                                    State
                                                </label>
                                                <Input
                                                    className='form-control-alternative'
                                                    id='state'
                                                    value={values.state}
                                                    onChange={handleChange}
                                                    placeholder='State'
                                                    type='text'
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg='6'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='postalCode'
                                                >
                                                    Post Code
                                                </label>
                                                <Input
                                                    className='form-control-alternative'
                                                    id='postalCode'
                                                    value={values.postalCode}
                                                    onChange={handleChange}
                                                    placeholder='Post Code'
                                                    type='text'
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg='6'>
                                           
                                        </Col>
                                    </Row>
                                   
                                    <AvaiSources values={values} handleChange={handleChange} />

                                    <hr className='my-4' />

                                    <Col sm='3' md='3'>
                                        <label
                                            className='form-control-label mb-0'
                                            htmlFor='currency'
                                        >
                                            Is internal
                                        </label>
                                        <div>
                                            <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                                                <input 
                                                    type='checkbox' 
                                                    id='isInternal'
                                                    checked={values.isInternal}
                                                    onChange={handleChange}
                                                />
                                                <span className='custom-toggle-slider rounded-circle' data-label-off='OFF' data-label-on='ON'></span>
                                            </label>
                                        </div>
                                    </Col>
                                    <hr className='my-4' />
                                    <FormGroup>
                                        <label>About User</label>
                                        <Input
                                            className='form-control-alternative'
                                            placeholder='A few words about user ...'
                                            rows='4'
                                            id='description'
                                            value={values.description}
                                            onChange={handleChange} 
                                            type='textarea'
                                        />
                                    </FormGroup>
                                </div>
                                <div className='modal-footer'>
                                    <Button
                                        color='secondary'
                                        data-dismiss='modal'
                                        type='button'
                                        onClick={props.toggleModal}
                                    >
                                        Close
                                    </Button>
                                    <Button 
                                        color='primary' 
                                        type='submit'
                                    >
                                        Confirm
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
};
 
export default UserModal;