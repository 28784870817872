import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';

// nodejs library that concatenates classes
import classnames from 'classnames';

// reactstrap components
import {
    Button,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Row,
    Col
} from 'reactstrap';
import AvaiSources from './AvaiSources.js';

import client from '../../feathers.js';

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Email is invalid')
        .required('Email is required'),
    password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required')
});

const UserModal = (props) => {
    const [ emailFocused, setEmailFocused ] = useState(false);
    const [ passwordFocused, setPasswordFocused ] = useState(false);
    const [ confirmPasswordFocused, setConfirmPasswordFocused ] = useState(false);

    const onSubmit = async(value) => {
        value.username = value.email;
    
        client.authenticate()
            .then(()=>{
                const data = {
                    ...value,
                    superAdminId: props.userId,
                    isInternal: props.userInfo.isInternal,
                };
                return client.service('users').create(data);
            })
            .then((res) => {
                props.toggleModal();
                props.pushUsers(res);
                props.notificationOpen(true, 'success', 'User ' + res.email + ' Added Successfully!');
            })
            .catch((err)=>{
                if(err.name === 'NotAuthenticated'){
                    props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                }else{
                    props.notificationOpen(true, 'danger', err.message);
                }
            });
    };

    return (  
        <>
            <Modal
                className='modal-dialog-centered'
                isOpen={props.modalVisible}
                toggle={props.toggleModal}
            >
                <div className='modal-header'>
                    <h5 className='modal-title' id='exampleModalLabel'>
                        Add User
                    </h5>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={props.toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        confirmPassword: '',
                        firstName: '',
                        lastName: '',
                        phoneNumber: '',
                        address: '',
                        state: '',
                        country: '',
                        postalCode: '',
                        numbOfMargins: 200,
                        numbOfActiveProduct: 200,
                        startDate: new Date(),
                        endDate: new Date(),
                        description: '',
                        role: 'admin',
                        currency: 'myr',
                        avaiSources: {
                            csv: true,
                            api: false,
                            apiSg: false,
                            apiEast: false,
                            poster: true,
                            looseSheet: true,
                            booklet: true,
                        }
                    }}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                >
                    {propsChild => {
                        const {
                            values,
                            touched,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        } = propsChild;
                        return (
                            <Form role='form' onSubmit={handleSubmit}>
                                <div className='modal-body pt-0'>
                                    <Row>
                                        <Col lg='12'>
                                            <label
                                                className='form-control-label'
                                                htmlFor='email'
                                            >
                                                Email Address
                                            </label>
                                            <FormGroup
                                                className={classnames('mb-3', {
                                                    focused: emailFocused
                                                }, {
                                                    'has-danger': errors.email && touched.email
                                                })}
                                            >
                                                <InputGroup className='input-group-alternative'>
                                                    <InputGroupAddon addonType='prepend'>
                                                        <InputGroupText>
                                                            <i className='ni ni-email-83' />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        placeholder='Email'
                                                        type='email'
                                                        id='email'
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onFocus={e =>
                                                            setEmailFocused(true)
                                                        }
                                                        onBlur={e => {
                                                            setEmailFocused(false);
                                                            handleBlur(e);
                                                        }}
                                                    />
                                                </InputGroup>
                                                {errors.email && touched.email && (
                                                    <div className='input-feedback'>{errors.email}</div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg='6'>
                                            <label
                                                className='form-control-label'
                                                htmlFor='Passowrd'
                                            >
                                                Password
                                            </label>
                                            <FormGroup
                                                className={classnames({
                                                    focused: passwordFocused
                                                }, {
                                                    'has-danger': errors.password && touched.password
                                                })}
                                            >
                                                <InputGroup className='input-group-alternative'>
                                                    <InputGroupAddon addonType='prepend'>
                                                        <InputGroupText>
                                                            <i className='ni ni-lock-circle-open' />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        placeholder='Password'
                                                        type='password'
                                                        autoComplete='off'
                                                        id='password'
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        onFocus={e =>
                                                            setPasswordFocused(true)
                                                        }
                                                        onBlur={e => {
                                                            setPasswordFocused(false);
                                                            handleBlur(e);
                                                        }}
                                                    />
                                                </InputGroup>
                                                {errors.password && touched.password && (
                                                    <div className='input-feedback'>{errors.password}</div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col lg='6'>
                                            <label
                                                className='form-control-label'
                                                htmlFor='confirmPassword'
                                            >
                                                Confirm Password
                                            </label>
                                            <FormGroup
                                                className={classnames({
                                                    focused: confirmPasswordFocused
                                                }, {
                                                    'has-danger': errors.confirmPassword && touched.confirmPassword
                                                })}
                                            >
                                                <InputGroup className='input-group-alternative'>
                                                    <InputGroupAddon addonType='prepend'>
                                                        <InputGroupText>
                                                            <i className='ni ni-lock-circle-open' />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        placeholder='Confirm Password'
                                                        type='password'
                                                        autoComplete='off'
                                                        id='confirmPassword'
                                                        value={values.confirmPassword}
                                                        onChange={handleChange}
                                                        onFocus={e =>
                                                            setConfirmPasswordFocused(true)
                                                        }
                                                        onBlur={e => {
                                                            setConfirmPasswordFocused(false);
                                                            handleBlur(e);
                                                        }}
                                                    />
                                                </InputGroup>
                                                {errors.confirmPassword && touched.confirmPassword && (
                                                    <div className='input-feedback'>{errors.confirmPassword}</div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg='6'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='firstName'
                                                >
                                                    First name
                                                </label>
                                                <Input
                                                    className='form-control-alternative'
                                                    id='firstName'
                                                    value={values.firstName}
                                                    onChange={handleChange}
                                                    placeholder='First name'
                                                    type='text'
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg='6'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='lastName'
                                                >
                                                    Last name
                                                </label>
                                                <Input
                                                    className='form-control-alternative'
                                                    id='lastName'
                                                    value={values.lastName}
                                                    onChange={handleChange}
                                                    placeholder='Last name'
                                                    type='text'
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="gender"
                      >
                        Gender
                      </label>
                        <Input 
                          className="form-control-alternative"
                          placeholder="Gender"
                          type="select" 
                          id="gender" 
                          value={values.gender}
                          onChange={handleChange}
                        >
                        <option>male</option>
                        <option>female</option>
                        <option>other</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="birth"
                      >
                        Birth
                      </label>
                      <Input 
                        className="form-control-alternative"
                        placeholder="Birth"
                        type="date"
                        id="birth"
                        value={values.birth}
                        onChange={handleChange} 
                      />
                    </FormGroup>
                  </Col>
                </Row> */}
                                    <Row>
                                        <Col lg='6'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='currency'
                                                >
                                                    Currency
                                                </label>
                                                <Input 
                                                    className='form-control-alternative text-uppercase'
                                                    placeholder='Currency'
                                                    type='select' 
                                                    id='currency' 
                                                    value={values.currency}
                                                    onChange={handleChange}
                                                >
                                                    <option>myr</option>
                                                    <option>sgd</option>
                                                    <option>idr</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col lg='6'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='phoneNumber'
                                                >
                                                    Contact Number
                                                </label>
                                                <Input
                                                    className='form-control-alternative'
                                                    id='phoneNumber'
                                                    value={values.phoneNumber}
                                                    onChange={handleChange}
                                                    placeholder='Contact'
                                                    type='text'
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <hr className='my-4' />
                                    <Row>
                                        <Col lg='6'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='currency'
                                                >
                                                    Max Margins
                                                </label>
                                                <Input
                                                    className='form-control-alternative'
                                                    type='number'
                                                    id='numbOfMargins'
                                                    value={Number(values.numbOfMargins).toString()}
                                                    min={3}
                                                    max={999}
                                                    onChange={handleChange}
                                                    placeholder='Number Of Margins'
                                                    onWheel={ event => event.currentTarget.blur() }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg='6'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='currency'
                                                >
                                                    Max Active Product
                                                </label>
                                                <Input
                                                    className='form-control-alternative'
                                                    type='number'
                                                    id='numbOfActiveProduct'
                                                    value={Number(values.numbOfActiveProduct).toString()}
                                                    min={5}
                                                    max={999}
                                                    onChange={handleChange}
                                                    placeholder='Number Of Active Product'
                                                    onWheel={ event => event.currentTarget.blur() }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                   
                                    <AvaiSources values={values} handleChange={handleChange} />

                                    <hr className='my-4' />
                                    <Row>
                                        <Col lg='6'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='currency'
                                                >
                                                    Start date
                                                </label>
                                                <Input
                                                    className='form-control-alternative'
                                                    type='date'
                                                    id='startDate'
                                                    value={values.startDate}
                                                    onChange={handleChange}
                                                    placeholder='Start Date'
                                                    onWheel={ event => event.currentTarget.blur() }
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg='6'>
                                            <FormGroup>
                                                <label
                                                    className='form-control-label'
                                                    htmlFor='currency'
                                                >
                                                    End date
                                                </label>
                                                <Input
                                                    className='form-control-alternative'
                                                    type='date'
                                                    id='endDate'
                                                    value={values.endDate}
                                                    onChange={handleChange}
                                                    placeholder='End date'
                                                    onWheel={ event => event.currentTarget.blur() }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <hr className='my-4' />
                                    {/* Address */}
                                    {/* <Row>
                  <Col md="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="address"
                      >
                        Address
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="address"
                        value={values.address}
                        onChange={handleChange} 
                        placeholder="Home Address"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="state"
                      >
                        state
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="state"
                        value={values.state}
                        onChange={handleChange} 
                        placeholder="state"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="country"
                      >
                        Country
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="country"
                        value={values.country}
                        onChange={handleChange} 
                        placeholder="Country"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="postalCode"
                      >
                        Postal code
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="postalCode"
                        value={values.postalCode}
                        onChange={handleChange} 
                        placeholder="Postal code"
                        type="number"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr className="my-4" /> */}
                                    {/* Description */}
                                    <FormGroup>
                                        <label>About User</label>
                                        <Input
                                            className='form-control-alternative'
                                            placeholder='A few words about user ...'
                                            rows='4'
                                            id='description'
                                            value={values.description}
                                            onChange={handleChange} 
                                            type='textarea'
                                        />
                                    </FormGroup>
                                </div>
                                <div className='modal-footer'>
                                    <Button
                                        color='secondary'
                                        data-dismiss='modal'
                                        type='button'
                                        onClick={props.toggleModal}
                                    >
                                        Close
                                    </Button>
                                    <Button 
                                        color='primary' 
                                        type='submit'
                                    >
                                        Confirm
                                    </Button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
};
 
const mapStateToProps = state => ({
    userId: state.role.details.user?state.role.details.user._id:'',
    userInfo: state.role.details.user
});

const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(UserModal);