import React, { useState, useEffect } from 'react';
import { Formik, Field, FieldArray } from 'formik';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import * as Yup from 'yup';
import Dropdown from '../Common/Dropdown.js';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/common/delete.svg';
import { ReactComponent as DoneStepIcon } from '../../assets/img/icons/common/doneStep.svg';

import ComponentNumberInput from './ComponentNumberInput.js';
import ComponentTextInput from './ComponentTextInput.js';
import DynamicPriceInput from './DynamicPriceInput.js';

import { withRouter } from 'react-router';
import env from '../../env.js';

// reactstrap components
import {
    Button,
    Form,
    Row,
    Col,
    Card,
    CardBody,
    InputGroup
} from 'reactstrap';

import 'react-modern-drawer/dist/index.css';

import client from '../../feathers.js';

const validationSchema = Yup.object().shape({
    code: Yup.string().required('Code is required'),
    name: Yup.string().required('Name is required'),
    applicableSource: Yup.string().required('Source is required!'),
});

const Quantity = (props) => {
    const { isEdit, componentId, dynamicPriceData, componentsTypeId, userId, componentTypeName, onSubmit, duplicate } = props;
    const [supportedSource, setSupportedSource] = useState(env.componentSources);
    const [existingComponent, setExistingComponent] = useState();

    useEffect(() => {
        setSupportedSource(env.componentSources.filter((x) => { return (x.value !== 'csv' && x.value !== 'api'); }));
    }, []);
    
    useEffect(async () => {
        if (componentId) {
            await client.authenticate()
                .then(()=>{
                    return client.service('components').get(componentId);
                })
                .then((res) => {
                    setExistingComponent({
                        ...res,
                        code: duplicate ? '' : res.code
                    });
                })
                .catch((err)=>{
                    if(err.name === 'NotAuthenticated'){
                        props.notificationOpen(true, 'danger', 'Please Sign-in to continue!');
                    }else{
                        props.notificationOpen(true, 'danger', err.message);
                    }
                });
        }
    }, []);


    const renderMinMax = (values, setFieldValue) => {
        return (
            <Row className='m-0 mb-4 d-flex align-items-center'>
                <Col md='4' className='p-0'>
                    <ComponentNumberInput
                        type={'number'}
                        className='mr-2'
                        title={'Minimum'}
                        location={'data.quantity.min'}
                    />
                </Col>

                <Col md='4' className='p-0'>
                    {values.data.quantity.isInfinite ? (
                        <Row className={'m-0 d-flex align-items-center justify-content-between rounded bg-white mr-2'}>
                            <div className='input-card d-flex align-items-center'>
                                <small className='mr-1 mb-0 w-50' >Maximum</small>
                                <InputGroup className='input-group-alternative d-flex align-items-center p-1 border-0' style={{ borderRadius: 50, marginRight: 5, background: '#EBF5FF' }}>
                                    <Field 
                                        className='form-control form-control-sm form-control-alternative mr-1 bg-transparent'
                                        style={{ borderRadius: 50 }}
                                        type='string'
                                        onWheel={(e) => e.target.blur()}
                                        value={'∞'}
                                        disabled
                                    />
                                </InputGroup>
                            </div>
                        </Row>
                    ) : (
                        <ComponentNumberInput
                            type={'number'}
                            className='mr-2'
                            title={'Maximum'}
                            location={'data.quantity.max'}
                            disabled={values.data.quantity.isInfinite}
                        />
                    )}
                </Col>

                <Col md='4' className='d-flex align-items-center'>
                    <small className='mx-2'>Close</small>
                    <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                        <input 
                            type='checkbox' 
                            checked={values.data.quantity.isInfinite}
                            onClick={() => setFieldValue('data.quantity.isInfinite', !values.data.quantity.isInfinite)}
                        />
                        <span className='custom-toggle-slider rounded-circle' data-label-off='OFF' data-label-on='ON'></span>
                    </label>
                    <small className='mx-2'>Infinite</small>

                </Col>
            </Row>
        );
    };

    const renderCustomWithIncrement = (values) => {
        return (
            <>
                <Row className='m-0 mb-2 d-flex align-items-center'>
                    <Col md='3' className='p-0'>
                        <ComponentNumberInput
                            type={'number'}
                            className='mr-2'
                            title={'Start'}
                            location={'data.quantity.start'}
                        />
                    </Col>

                    <Col md='5' className='p-0'>
                        <ComponentNumberInput
                            type={'number'}
                            className='mr-2'
                            title={'In Multiple Of'}
                            location={'data.quantity.inMultipleOf'}
                        />
                    </Col>

                    <Col md='3' className='p-0'>
                        <ComponentNumberInput
                            type={'number'}
                            className='mr-2'
                            title={'End'}
                            location={'data.quantity.end'}
                        />
                    </Col>
                </Row>
                <Row className='m-0 d-flex align-items-center'>
                    <small className='mr-1 mb-1'>Custom Quantities</small>
                </Row>
                <FieldArray
                    className='mb-2'
                    name='data.quantity.custom'
                    render={({ push, remove, move }) => (
                        <>
                            <Col className='mb-3 p-0'>
                                <DragDropContext onDragEnd={(result) => {
                                    if (!result.destination) {
                                        return;
                                    }
                        
                                    move(result.source.index, result.destination.index);
                                }}>

                                    <Droppable droppableId='quantity'>
                                        {(provided) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                {values.data.quantity.custom.map((quantityDetail, index) => (
                                                    <Draggable key={index} draggableId={`quantityDetail-${index}`} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <Row key={index} className='m-0 mb-1 align-items-center justify-content-start'>
                                                                    <Col md='4' className='pr-0'>
                                                                        <Row className='w-100 d-flex align-items-center flex-nowrap'>
                                                                            <ComponentNumberInput
                                                                                type={'number'}
                                                                                className='mr-2'
                                                                                title={'Quantity'}
                                                                                location={`data.quantity.custom[${index}].quantity`}
                                                                                hasDrag
                                                                            />
                                                                        </Row>
                                                                    </Col>

                                                                    <Col className='p-0'>
                                                                        <Button 
                                                                            key={'asd'}
                                                                            color='danger'
                                                                            className='btn-round'
                                                                            onClick={() => remove(index)}
                                                                        >
                                                                            <DeleteIcon 
                                                                                width={14}
                                                                                height={14}
                                                                            />
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Col>

                            <Row className='d-flex align-items-start mb-4' style={{ paddingLeft: 15}}>
                                <a 
                                    href='/' 
                                    onClick={(event) => {
                                        event.preventDefault();
                                        push({
                                            quantity: undefined,
                                        });
                                    }}      
                                >
                                    <span>
                                        <i className='mr-1 fa fa-plus-circle' style={{fontSize: '1rem'}}/>
                                        Add New Quantity
                                    </span>
                                </a>
                            </Row>
                        </>
                    )}
                />

                
            </>
        );
    };

    const renderQuantitySettings = (values, setFieldValue) => {
        if(values.applicableSource === 'product'){ 
            return (
                <>
                    <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-center'>
                        <Col md='11'>
                            <div style={{ width: 290 }}>
                                <Row className='m-0 d-flex align-items-center'>
                                    <small className='mr-1 mb-1'>Quantity Minimum Amount*</small>
                                </Row>
                                <ComponentNumberInput
                                    type={'number'}
                                    className='mr-2'
                                    title={'Minimum'}
                                    location={'data.quantity.min'}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-start'>                                                
                        <Col md='12'>
                            <Row className='m-0 d-flex align-items-center'>
                                <Col md='2' className='p-0 pb-1'>
                                    <small className='mr-5 mb-1'>Quantity Details* </small>
                                </Col>
                            </Row>
                            <FieldArray
                                className='mb-3'
                                name='data.quantity.variableQuantity'
                                render={({ push, remove, move }) => (
                                    <>
                                        <Col className='mb-3 p-0'>
                                            <DragDropContext onDragEnd={(result) => {
                                                if (!result.destination) {
                                                    return;
                                                }

                                                move(result.source.index, result.destination.index);
                                            }}>

                                                <Droppable droppableId='quantity.variableQuantity'>
                                                    {(provided) => (
                                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                                            {values.data.quantity.variableQuantity.map((quantityDetail, index) => (
                                                                <Draggable key={index} draggableId={`quantityDetail-${index}`} index={index}>
                                                                    {(provided) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <Row key={index} className='m-0 mb-1 align-items-center justify-content-start'>
                                                                                <Col md='2' className='pr-0'>
                                                                                    <Row className='w-100 d-flex align-items-center flex-nowrap'>
                                                                                        <ComponentTextInput
                                                                                            hasDrag
                                                                                            placeholder={'Label'}
                                                                                            location={`data.quantity.variableQuantity[${index}].label`}
                                                                                        />
                                                                                    </Row>
                                                                                </Col>
                                            
                                                                                <Col md='3' className='p-0'>
                                                                                    <ComponentNumberInput
                                                                                        type={'number'}
                                                                                        className='mr-2'
                                                                                        title={'Base Price'}
                                                                                        location={`data.quantity.variableQuantity[${index}].basePrice`}
                                                                                    />
                                                                                </Col>

                                                                                <Col md='3' className='p-0'>
                                                                                    <ComponentNumberInput
                                                                                        type={'number'}
                                                                                        className='mr-2'
                                                                                        title={'Per Price'}
                                                                                        location={`data.quantity.variableQuantity[${index}].perPrice`}
                                                                                    />
                                                                                </Col>

                                                                                <Col md='3' className='p-0'>
                                                                                    <DynamicPriceInput
                                                                                        setFieldValue={setFieldValue}
                                                                                        dynamicPriceData={dynamicPriceData.filter((x) => x.type !== 'sqft')}
                                                                                        location={`data.quantity.variableQuantity[${index}].dynamicPriceId`}
                                                                                    />
                                                                                </Col>
                                                                                <Col md='1'>
                                                                                    <Button 
                                                                                        color='danger'
                                                                                        className='btn-round'
                                                                                        onClick={() => remove(index)}
                                                                                    >
                                                                                        <DeleteIcon 
                                                                                            width={14}
                                                                                            height={14}
                                                                                        />
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>

                                                                            <Row key={`type-${index}`} className='m-0 mb-3 align-items-center justify-content-start'>
                                                                                <Col md='2' className='d-flex align-items-center'>
                                                                                    <small className='mx-2'>Min/Max</small>
                                                                                    <label className='custom-toggle custom-toggle-primary pb-0 mb-0'>
                                                                                        <input 
                                                                                            type='checkbox' 
                                                                                            checked={values.data.quantity.variableQuantity[index].isCustom}
                                                                                            onClick={() => setFieldValue(`data.quantity.variableQuantity[${index}].isCustom`, !values.data.quantity.variableQuantity[index].isCustom)}
                                                                                        />
                                                                                        <span className='custom-toggle-slider rounded-circle' data-label-off='OFF' data-label-on='ON'></span>
                                                                                    </label>
                                                                                    <small className='mx-2'>Custom</small>

                                                                                </Col>

                                                                                {(values.data.quantity.variableQuantity[index].isCustom)
                                                                                    ? (<>
                                                                                        <Col md='2' className='p-0'>
                                                                                            <ComponentNumberInput
                                                                                                type={'number'}
                                                                                                className='mr-2'
                                                                                                title={'Start'}
                                                                                                location={`data.quantity.variableQuantity[${index}].start`}
                                                                                            />
                                                                                        </Col>

                                                                                        <Col md='3' className='p-0'>
                                                                                            <ComponentNumberInput
                                                                                                type={'number'}
                                                                                                className='mr-2'
                                                                                                title={'In Multiple Of'}
                                                                                                location={`data.quantity.variableQuantity[${index}].inMultipleOf`}
                                                                                            />
                                                                                        </Col>

                                                                                        <Col md='2' className='p-0'>
                                                                                            <ComponentNumberInput
                                                                                                type={'number'}
                                                                                                className='mr-2'
                                                                                                title={'End'}
                                                                                                location={`data.quantity.variableQuantity[${index}].end`}
                                                                                            />
                                                                                        </Col>
                                                                                    </>)
                                                                                    : (<>
                                                                                        <Col md='2' className='p-0'>
                                                                                            <ComponentNumberInput
                                                                                                type={'number'}
                                                                                                className='mr-2'
                                                                                                title={'Minimum'}
                                                                                                location={`data.quantity.variableQuantity[${index}].min`}
                                                                                            />
                                                                                        </Col>

                                                                                        <Col md='2' className='p-0'>
                                                                                            <ComponentNumberInput
                                                                                                type={'number'}
                                                                                                className='mr-2'
                                                                                                title={'Maximum'}
                                                                                                location={`data.quantity.variableQuantity[${index}].max`}
                                                                                            />
                                                                                        </Col>
                                                                                    </>)}
                                                                            
                                                                            </Row>

                                                                        
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            <Row className='d-flex align-items-start mb-3' style={{ paddingLeft: 15}}>
                                                                <a 
                                                                    href='/' 
                                                                    onClick={(event) => {
                                                                        event.preventDefault();
                                                                        push({ label: '', basePrice: 0, perPrice: 0, min: 1, max: 100, inMultipleOf: 10, start: 1, end: 100});
                                                                    }}      
                                                                >
                                                                    <span>
                                                                        <i className='mr-1 fa fa-plus-circle' style={{fontSize: '1rem'}}/>
                                                                        Add Line
                                                                    </span>
                                                                </a>
                                                            </Row>
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        </Col>
                                    </>
                                )}
                            />
                        </Col>
                    </Row>
                </>
            );
        } else {
            return (
                <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-start'>                                                
                    <Col md='8' className='p-0 m-0 bg-light-grey'>
                        <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-center'>
                            <Col md='0.5' className='p-0 d-flex justify-content-center'>
                                <DoneStepIcon/>
                            </Col>
                            <Col md='11'>
                                <div style={{ width: 290 }}>
                                    <Row className='m-0 d-flex align-items-center'>
                                        <small className='mr-1 mb-1'>Quantity Type*</small>
                                    </Row>
                                    <Row className='m-0 px-3 d-flex align-items-center justify-content-between border rounded bg-white'>
                                        <label className='d-flex align-items-center mb-0 mr-2'>
                                            <Field style={{marginRight: '3px'}} type='radio' name='data.quantity.isCustom' value={false} default
                                                onChange={() => setFieldValue('data.quantity.isCustom', false)}/>
                                            <small className='mr-1 mb-0 w-10'>Min/Max</small>
                                        </label>
                                        <label className='d-flex align-items-center mb-0 mr-2'>
                                            <Field style={{marginRight: '3px'}} type='radio' name='data.quantity.isCustom' value={true}
                                                onChange={() => setFieldValue('data.quantity.isCustom', true)}
                                            />
                                            <small className='mr-1 mb-0 w-10'>Custom + Increment</small>
                                        </label>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row className='bg-light-grey px-4 py-3 rounded-bottom d-flex align-items-start'>
                            <Col md='0.5' className='p-0 d-flex justify-content-center'>
                                <DoneStepIcon/>
                            </Col>
                            <Col md='11'>
                                <Row className='m-0 d-flex align-items-center'>
                                    <small className='mr-1 mb-1'>Quantity Settings*</small>
                                </Row>
                                {(values.data.quantity.isCustom)
                                    ? renderCustomWithIncrement(values, setFieldValue)
                                    : renderMinMax(values, setFieldValue)}
                            </Col>
                        </Row>
                    </Col>
    
                    <Col md='4' className='p-0 m-0 bg-light-grey'>
                        {values.data.quantity.isCustom && (
                            <>
                                <Row className='p-0 d-flex align-items-center'>
                                    <h3>Preview</h3>
                                </Row>
                                <Row className='p-0 d-flex align-items-center'>
                                    <Dropdown
                                        setFieldValue={setFieldValue}
                                        values={[...getQuantities(values.data.quantity.isCustom, values)]}
                                        width={'auto'}
                                    />
                                </Row>
                            </>
                        )}
                
                    </Col>
                </Row>
            );
        }
        
    };

    const getQuantities = (isCustom, values) => {
        const data = [];
        if (values.data.quantity.inMultipleOf) {
            if (isCustom) {
                if (values.data.quantity.start !== 0 && values.data.quantity.end !== 0) {
                    for (let i = values.data.quantity.start; i <= values.data.quantity.end; i += values.data.quantity.inMultipleOf) {
                        data.push(i);
                    }
                }
                
                const custom = values.data.quantity.custom.map((x) => x.quantity);
                data.push(...custom);
            } else {
                for (let i = values.data.quantity.min; i <= values.data.quantity.max; i += 1) {
                    data.push(i);
                }
            }
        } else {
            const custom = values.data.quantity.custom.map((x) => x.quantity);
            data.push(...custom);
        }
        const sorted = [...new Set(data)].filter((x) => (x)).sort((a, b) => a - b);
        return sorted.map((x) => {
            return {
                value: x,
                display: x,
            };
        });
    };

    return (  
        <>
            <Formik
                initialValues={{
                    _id: (existingComponent && !duplicate) ? existingComponent._id : undefined,
                    userId,
                    applicableSource: (existingComponent) ? existingComponent.applicableSource : undefined,
                    allowAllSource: (existingComponent) ? existingComponent.allowAllSource : true,
                    code: (existingComponent) ? existingComponent.code : '',
                    name: (existingComponent) ? existingComponent.name : '',
                    description: (existingComponent) ? existingComponent.description : '',
                    dynamicPriceId: (existingComponent) ? existingComponent.dynamicPriceId : undefined,
                    componentsTypeId,
                    componentTypeName,
                    status: (existingComponent) ? existingComponent.status : 1,
                    data: (existingComponent) ? existingComponent.data : {
                        quantity: {
                            isCustom: false,
                            min: 1,
                            max: 100,
                            isInfinite: false,
                            custom: [{
                                quantity: 0,
                            }],
                            start: 1,
                            inMultipleOf: 10,
                            end: 100,
                            variableQuantity: [{
                                isCustom: false,
                                min: 1,
                                max: 100,
                                isInfinite: false,
                                custom: [{
                                    quantity: 0,
                                }],
                                start: 1,
                                inMultipleOf: 10,
                                end: 100,
                                basePrice: 0,
                                perPrice: 0,
                            }]
                        },
                    }
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                enableReinitialize={true}
            >
                {propsChild => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                    } = propsChild;

                    const handleSourceChange = (value) => {
                        setFieldValue('applicableSource', value);
                        if(value === 'product') {
                            setFieldValue('allowAllSource', false);
                        } else {
                            setFieldValue('allowAllSource', true);
                        }
                    };
                    
                    return (
                        <Form role='form' onSubmit={handleSubmit}>
                            <div className='modal-body p-0'>
                                <Card className='shadow mb-2'>
                                    <CardBody className='rounded'>
                                        <div>
                                            <div className='px-3 mb-4 border rounded'>
                                                <Row className='p-3 px-4'>
                                                    <h4 className='m-0'>{componentTypeName} Information</h4>
                                                </Row>
                                                <Row className='bg-secondary p-2 py-4 rounded-bottom'>
                                                    <Col md='2'>
                                                        <h5 className='mb-1'>Applicable for:</h5>
                                                        <Dropdown
                                                            name={'applicableSource'}
                                                            setFieldValue={setFieldValue}
                                                            callback={handleSourceChange}
                                                            disabled={(existingComponent)}
                                                            values={[
                                                                {
                                                                    value: undefined,
                                                                    display: 'Excluding SC-Item'
                                                                },
                                                                {
                                                                    value: 'product',
                                                                    display: 'SC-Item'
                                                                }
                                                            ]}
                                                            width={'100%'}
                                                        />
                                                        {errors.applicableSource && touched.applicableSource ? (
                                                            <small className='text-danger xs'>{errors.applicableSource}</small>
                                                        ) : null}
                                                    </Col>
                                                    <Col md='2'>
                                                        <h5 className='mb-1'>Code</h5>
                                                        <Field 
                                                            className='form-control form-control-sm form-control-alternative'
                                                            placeholder='Code'
                                                            type='text'
                                                            name={'code'}
                                                            maxlength='8'
                                                            normalize={value => (value || '').toUpperCase()}
                                                        />
                                                        {errors.code && touched.code ? (
                                                            <small className='text-danger xs'>{errors.code}</small>
                                                        ) : null}
                                                    </Col>
                                                    <Col md='4'>
                                                        <h5 className='mb-1'>Name</h5>
                                                        <Field 
                                                            className='form-control form-control-sm form-control-alternative'
                                                            placeholder='Name'
                                                            type='text'
                                                            name={'name'}
                                                            maxlength='40'
                                                        />
                                                        {errors.name && touched.name ? (
                                                            <small className='text-danger xs'>{errors.name}</small>
                                                        ) : null}
                                                    </Col>
                                                    <Col md='4'>
                                                        <h5 className='mb-1'>Description</h5>
                                                        <Field 
                                                            className='form-control form-control-sm form-control-alternative'
                                                            placeholder='Description'
                                                            type='text'
                                                            name={'description'}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className='px-3 mb-2 border rounded'>
                                                <Row className='p-3 px-4'>
                                                    <h4 className='m-0'>{componentTypeName} Properties</h4>
                                                </Row>

                                                {renderQuantitySettings(values, setFieldValue)}
                                            </div>
                                        </div>
                                        <div className='modal-footer'>
                                            <Button
                                                color='secondary'
                                                data-dismiss='modal'
                                                type='button'
                                                onClick={() => {
                                                    props.history.push(`/${props.role}/components`);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                color='secondary' 
                                                type='submit'
                                                onClick={() => setFieldValue('status', 0)}
                                            >
                                                Save as Draft
                                            </Button>
                                            <Button
                                                color='primary' 
                                                type='submit'
                                                onClick={() => setFieldValue('status', 1)}
                                            >
                                                Save as Active
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};
 
export default withRouter(Quantity);